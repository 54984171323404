import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useLanguage } from '@hooks/useLanguage'
import { DealerContacts } from '@layout/DealerContacts'
import { CardInfo } from '@layout/types'
import { Page, PageContainerWithHead, PageContent } from '@layout/Page'
import { FlexContainer } from '@layout/FlexContainer'
import { ContactForm } from '@forms/ContactForm'
import { buildCardsForGuaranteePage } from './utils'
import { ItemGuarantee } from './ItemGuarantee'

export const CardSection: React.FC = () => {
  const { t } = useTranslation()

  const cardItems: ReadonlyArray<CardInfo> = React.useMemo(() => buildCardsForGuaranteePage(t), [t])

  return (
    <FlexContainer vertical alignItemsCenter>
      {cardItems.map((card) => (
        <ItemGuarantee key={card.id} card={card} />
      ))}
    </FlexContainer>
  )
}

export const GuaranteePage: React.FC = () => {
  const { t } = useTranslation()
  const { routes } = useLanguage()
  const pageId = React.useId()

  const breadCrumbs = React.useMemo(
    () => [{ to: routes.Index, title: t('pages.main.title') }, { title: t('pages.guarantee.title') }],
    [t, routes]
  )

  return (
    <Page id={pageId}>
      <PageContainerWithHead showCar title={t('pages.guarantee.title')} breadCrumbs={breadCrumbs}>
        <PageContent subtitle={t('pages.guarantee.text')}>
          <CardSection />
          <ContactForm topic="other" />
          <DealerContacts />
        </PageContent>
      </PageContainerWithHead>
    </Page>
  )
}
