import * as React from 'react'
import { Card, CardContent, CardHeader, CardMedia } from '@mui/material'
import { CardInfo } from '@layout/types'
import styles from './ItemGuarantee.module.css'
import { FlexContainer } from '@layout/FlexContainer'

type Props = Readonly<{ card: CardInfo }>

export const ItemGuarantee: React.FC<Props> = ({ card }) => {
  return (
    <Card className={styles.card} elevation={0}>
      <CardMedia image={card.image} component="img" alt={card.title} />
      <FlexContainer vertical>
        <CardHeader className={styles.title} title={card.title} avatar={<img src={card.icon} alt={card.title} />} />
        <CardContent className={styles.content}>{card.text}</CardContent>
      </FlexContainer>
    </Card>
  )
}
