import { TFunction } from 'i18next'
import img1 from '@assets/images/img_headlamp.png'
import img2 from '@assets/images/img_engine.png'
import img3 from '@assets/images/img_speedometer.png'
import icon1 from '@assets/icons/icon_factoryGuarantee.svg'
import icon2 from '@assets/icons/icon_carProfGuarantee.svg'
import icon3 from '@assets/icons/icon_mileageGuarantee.svg'
import { CardInfo } from '@layout/types'

export const buildCardsForGuaranteePage = (t: TFunction): Array<CardInfo> => [
  {
    id: 1,
    image: img1,
    title: t('pages.guarantee.factoryGuarantee'),
    icon: icon1,
    text: t('pages.guarantee.manufacturerObligation')
  },
  {
    id: 2,
    image: img2,
    title: t('pages.guarantee.carProfGuarantee'),
    icon: icon2,
    text: t('pages.guarantee.carProfGuaranteeText')
  },
  {
    id: 3,
    image: img3,
    title: t('pages.guarantee.mileageGuarantee'),
    icon: icon3,
    text: t('pages.guarantee.checkControl')
  }
]
